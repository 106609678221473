.Services {
  display: flex;
  white-space: pre-wrap;
  background-color: black;
  justify-content: center;
  color: white;
  font-size: calc(10px + 2vmin);
  margin-top: 20px;
  margin-bottom: 20px;
}

.Card{
  background-color:black;
  padding: 20px;
  white-space: pre-wrap;
  text-align: center;
}

.Card .Img{
  max-width: auto;
  height: auto;
}

.Card .Title{
  padding: 10px;
  color: #c5b53b;
  font-size: calc(20px + 2vmin);
}



