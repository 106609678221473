.Navbar {
  background-color: #c5b53b;
  position: relative;
  display: flex;
  overflow-y: hidden;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  font-style: italic;
  font-size: calc(10px + 2vmin);
  padding-top: 10px;
  padding-bottom: 2px;
}

.Navbar img {
 max-width: 350px;
 height: 100px;
}

.Nav{
  color: white;
}

.Navbar .Dropdown{
  background-color: #c5b53b;
  max-width: 100%; 
  ;height: 100%;
}
