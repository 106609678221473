.Gallery {
  background-color: black;
  color: white;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
}
.Gallery p {
  color: white;
}

.Gallery .galleryimg {
  max-width: 100%;
  max-height: 400px;
  margin-top: 10px;
  margin-bottom: 100px;
  perspective: 1000px;
  transform-style: preserve-3d;
}