

.Contact {
  background-color: black;
  color: white;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.Contact h3 {
  color:  #c5b53b;
  white-space: pre-wrap;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: calc(20px + 2vmin);
}

.Contact h2{
  color: white;
  white-space: pre-wrap;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: calc(20px + 2vmin);
}

.Contact table, td, tr{
  color: white;
  white-space: pre-wrap;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: calc(10px + 2vmin);
}

.Contact th{
  text-align: center;
  column-span: all;
  color: #c5b358;
}

.Contact p {
  white-space: pre-wrap;
  text-align: center;
  font-size: calc(10px + 2vmin);
}

.Contact a {
  word-wrap: break-word;
}

.Contact .Button {
  border: 1px solid white;
  padding: 16px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  background-color: #c5b53b;
  font: inherit;
  color: black;
  cursor: pointer;
}

.Contact .Button:hover,
.Button:active,
.Button:focus {
  border: 1px solid white !important;
  background-color: #c5b358 !important;
  color: white;
  outline: none;
  box-shadow: none !important;
}

.Contact .Hours {
  color:  #c5b53b;
}
