.SocialMedia {
  background-color: #c5b53b;
  color: blue;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  font-size: calc(5px + 2vmin);
  font-style: italic;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: auto;
}

.SocialMedia h3 {
  color: white;
  font-size: calc(8px + 2vmin);
  text-align: center;
}
