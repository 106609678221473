.Home {
  background-color: black;
  align-items: center;
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Home h3 {
  color: #c5b53b;
}

.Home p {
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Home button {
  border: 1px solid white;
  padding: 16px;
  padding-bottom: 20px;
  background-color: #c5b53b;
  font: inherit;
  color: black;
  cursor: pointer;
}

.Home button:hover,
button:active,
button:focus {
  border: 1px solid white !important;
  background-color: #c5b358 !important;
  color: white;
  outline: none;
  box-shadow: none !important;
}

.Home img {
  max-width: 100%;
}
