.About {
  background-color: black;
  position: relative;
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
}

.About p {
  text-align: center;
  font-size: calc(10px + 2vmin);
  margin-top: 10px;
  color: white;
}

.About h3 {
  text-align: center;
  font-size: calc(20px + 2vmin);
  color:  #c5b53b;
  margin-top: 10px;
}
